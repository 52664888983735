<template>
  <div>
    <Menu/>
    <div class="address">
      <p>
        Evžen Hanzalík <br />
        Telefon: +420 607 767 602 <br />
        E-mail: hanzalik.evzen@seznam.cz
      </p>
    </div>
    <v-card class="card" width="inherit">
      <v-card-text>
        <h3>Poptávkový a kontaktní formulář</h3>
      </v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="name"
          :counter="20"
          :rules="nameRules"
          label="Jméno"
          required
        ></v-text-field>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
        ></v-text-field>

        <v-text-field v-model="phoneNumber" label="Telefon"></v-text-field>

        <v-textarea
          v-model="text"
          :counter="150"
          label="Text"
          :rules="textRules"
        >
        </v-textarea>

        <v-btn
          :disabled="!valid"
          color="black"
          class="odeslat_btn"
          @click="validate"
          absolute
          right
          large
        >
          ODESLAT
        </v-btn>
      </v-form>
    </v-card>
    <div class="footer">
      <p v-on:click="showLogin">Doya6 &copy;2020</p>
    </div>
    <div class="login_form" v-if="loginShow || loggedIn" >
      <v-form ref="form">
        <h4>Správa webu - přihlášení</h4>
        <v-text-field v-model="userName" label="Jméno" required></v-text-field>
        <v-text-field v-model="pswd" label="Heslo" required></v-text-field>
      </v-form>
        <v-btn
          color="black"
          class="odeslat_btn"
          @click="login"
          large
          absolute
          right
        >
          {{ loggedIn ? "Logoff" : "Login" }}
        </v-btn>
    </div>

    <div v-if="loggedIn">
      <h4>Správa webu - nahrávání obrázků</h4>
      <v-form class="upload_file_form">

        <v-list dense class="category_selection">
          <v-list-item-group v-model="selectedCategory" color="primary">
            <v-list-item v-for="(item, i) in items" :key="i">
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-file-input
          v-model="file"
          class="file_input mt-2"
          dense
          flat
          label="vyberte soubor .jpg"
          type="file"
          filled
        ></v-file-input>
        <v-btn
          color="black"
          class="odeslat_obr_btn"
          v-on:click="uploadImage('obrazy')"
          large
          >Nahrát obrázek do {{ this.items[this.selectedCategory].text }}</v-btn
        >
      </v-form>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import Menu from '../components/Menu';

export default {
  components: {
    Menu
  },
   created: function () {
    this.loggedIn = this.$store.getters.getLoggedIn;
  },
  data: () => ({
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Jméno musí být vyplněno.",
      (v) => (v && v.length <= 20) || "Jméno musí být max 20 znaků.",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail musí být vyplněn.",
      (v) => /.+@.+\..+/.test(v) || "E-mail není platný",
    ],
    phoneNumber: "",
    text: "",
    textRules: [(v) => (v && v.length <= 150) || "Max 150 znaků."],
    loginShow: false,
    userName: "",
    pswd: "",
    loggedIn: Boolean,
    file: "",
    selectedCategory: 0,
    items: [{text:"Obrazy", value:"obrazy"}, {text:"Grafika", value:"grafika"}, {text:"Sochy", value:"sochy"}, {text:"Volná tvorba",value:"volna"}],
  }),

  methods: {
    validate() {
      if (this.$refs.form.validate()){
      axios
        .post("https://evzenhanzalik.cz/sendMail.php", {
          name: this.name,
          email: this.email, 
          phone: this.phoneNumber,
          text: this.text
          }
        )
        .then(() => {
          this.name = '';
          this.email = '';
          this.phoneNumber = '';
          this.text = '';
          alert("Vaše zpráva byla odeslána.");
        });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showLogin() {
      this.loginShow = !this.loginShow;
    },
    login() {
      if (this.loggedIn) {
        this.loggedIn = false;
        this.$store.commit('setLoggedIn', this.loggedIn);
      } else {
        axios
          .post("https://evzenhanzalik.cz/loginCheck.php", {
            user: `${this.userName}`,
            pswd: `${this.pswd}`,
          })
          .then((response) => {
            this.loggedIn = response.data;
            this.userName = "";
            this.pswd = "";
            this.$store.commit('setLoggedIn', this.loggedIn);
          });
      }
    },
    uploadImage(folder) {
      //this.file = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("folder", this.items[this.selectedCategory].value);
      console.log(this.file);
      console.log(folder);
      axios
        .post("https://evzenhanzalik.cz/file_upload.php", formData, {
          header: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          console.log(response.data);
          this.file = '';
        });
    },
  },
};
</script>

<style scoped>
.address {
  margin-top: 50px;
  text-align: center;
}
.card {
  padding: 20px 50px 100px 50px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.v-btn {
  margin: 20px;
}
.footer {
  display: flex;
  height: 60px;
  width: 800px;
  color: gray;
  background-color: #eeeeee;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.login_form {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  margin-bottom: 30px;
}
.upload_file_form {
  display: grid;
  grid-template-columns: auto 300px 100px 250px 20px;
  grid-template-rows: 30px 30px 30px 60px;
  margin-bottom: 60px;
}

h4 {
  grid-column: 2 / 5;
  grid-row: 1 / 2;
  text-align: center;
}
.file_input {
  grid-column: 4 / 5;
  grid-row: 2 / 3;
}
.category_selection {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}
.odeslat_obr_btn {
  grid-column: 3 / 6;
  grid-row: 4 / 5;
}
</style>